<script>
import Layout from '../layouts/main';
import { api } from '@/state/services';

export default {
  locales: {
    pt: {
      'Entities': 'Entidades',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Entities': 'Entidades',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      entities: {
        loading: true,
        empty: false,
        list: null,
      },

      entity: {
        modal: false,
        name: '',
        url: '',
        description: '',
        site: '',
        email: '',
        telephone: '',
        logo: '',
      }
    };
  },
  methods: {
    getEntity() {
      api
        .get('entity')
        .then((response) => {
          if (response.data.status == 'success') {
            this.entities.list = response.data.list
          } else {
            this.entities.empty = true
          }
        })

      this.entities.loading = false
    },
    showEntity(url) {
      api
        .get('entity/' + url)
        .then((response) => {
          if (response.data.status == 'success') {
            this.entity.name = response.data.name
            this.entity.url = response.data.url
            this.entity.description = response.data.description
            this.entity.site = response.data.site
            this.entity.email = response.data.email
            this.entity.telephone = response.data.telephone
            this.entity.logo = response.data.logo
          }
        })
      this.entity.modal = true
    }
  },
  mounted(){
    this.getEntity()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Entities') }}</h4>
        </div>
      </div>
    </div>

    <div v-if="entities.errored">
      <div class="card">
        <div class="card-body">
          {{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}
        </div>
      </div>
    </div>
    <div v-else-if="entities.empty">
      <div class="card">
        <div class="card-body">
          {{ t('No records found.') }}
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-xl-4 col-lg-4 col-md-6" v-for="(entity, index) in entities.list" :key="index">
        <!-- <a @click="showEntity(entity.url)"> -->
          <div class="card card-h-fix">
            <div class="card-body text-center">
              <div class="mb-3">
                <div v-if="entity.logo"><img style="max-height:120px;" :src="entity.logo" /></div>
                <div v-else><img style="max-height:120px;" src="@/assets/images/logo-icon.png" /></div>
              </div>
              <h5 class="mb-3">{{ entity.name }}</h5>
              <div v-html="entity.description"></div>
            </div>
          </div>
        <!-- </a> -->
      </div>
    </div>

    <b-modal
      v-model="entity.modal"
      size="lg"
      centered
      hide-footer
    >
      <div class="pb-5">
        <h1 class="mb-4 text-center">{{ entity.name }}</h1>
        <div class="row">
          <div class="col-lg-4 mb-4 text-center">
            <img v-if="entity.logo" style="max-height:150px;" :src="entity.logo" />
            <img v-else style="max-height:150px;" src="@/assets/images/logo-icon.png" />
            <div class="mt-2 text-center">
              {{ entity.site }}<br>
              {{ entity.email }}<br>
              {{ entity.telephone }}<br>
            </div>
          </div>
          <div class="col-lg-8">
            <div v-html="entity.description" class="mb-5"></div>
            <router-link :to="'/wallet/deposit/' + entity.url" class="btn btn-danger btn-lg btn-rounded">
              {{ t('Donate') }}
              <i class="mdi mdi-arrow-right ml-1"></i>
            </router-link>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>